// ToastContext.js
import React, { createContext, useState, useContext } from "react";
import { Toast, ToastContainer } from "react-bootstrap";

const ToastContext = createContext();

export function ToastProvider({ children }) {
    const [showToast, setShowToast] = useState(false);
    const [displayText, setDisplayText] = useState('')
    const [toastType, setToastType] = useState('success');

    function successToast(bodyText, bodyType = null) {
        setDisplayText(bodyText)
        if(bodyType != null) setToastType("error");
        setShowToast(true);
        setTimeout(() => setShowToast(false), 3000);        
        setTimeout(() => setToastType('success'), 3000);
    }

    return (
        <ToastContext.Provider value={{ successToast }}>
            {children}
            <ToastContainer position="bottom-end" className="p-3" style={{ zIndex: 2000 }}>
                <Toast bg="success" show={showToast} onClose={() => setShowToast(false)}>
                    <Toast.Header closeVariant="white" style={{ color: "white", backgroundColor: toastType == 'success' ? "#198754" : "#dbbc09", padding:'15px' }}>
                        <strong className="me-auto">{displayText}</strong>
                    </Toast.Header>
                </Toast>
            </ToastContainer>
        </ToastContext.Provider>
    );
}

export function useToast() {
    return useContext(ToastContext);
}
