import { useEffect } from "react"
import useFetch from "../../../hooks/useFetch"
import { ENDPOINTS } from "../../../constants/endpoints"
import { useSessionsDataContext } from "../providers/sessions"
import { usePastSessionsDataContext } from "../providers/pastSessions";
import { useUpcomingSessionsDataContext } from "../providers/upcomingSessions";
import { useToast } from "../../app/components/ToastContext";

function useDeleteDocument(commissionId = null) {
    const sessionData = useSessionsDataContext();
    const pastSessionData = usePastSessionsDataContext();
    const upcomingSessionData = useUpcomingSessionsDataContext();
    const { successToast } = useToast();

    const { fetch, loading } = useFetch();

    function handleSave(data, type, onHide, updateFilesCallback) {
        if (loading) return;
        console.log('DATA');
        console.log('END DATA');

        const endpoint = ENDPOINTS.SESSIONS.DELETE;
        const method = "post";

        fetch({
            endpoint,
            method,
            data,
        })
            .then(() => {
                // Refetch data for the relevant session type
                if (type === 'upcoming') upcomingSessionData.refetchDataUpcoming(upcomingSessionData.getCurrentPageUpcoming());
                if (type === 'past') pastSessionData.refetchDataPast(pastSessionData.getCurrentPagePast());

                // Success message
                if(data.get("type") == 'materials' || data.get("type") == 'protocols') successToast('Успешно изтрихте документ');
                else successToast('Успешно изтрихте заседание');

                // Update local files state
                if (updateFilesCallback) updateFilesCallback();

                // Close the modal
                onHide();
            });
    }

    return {
        loading,
        handleSave,
    };
}

export default useDeleteDocument
