import { Button, CloseButton, Form, Modal } from "react-bootstrap"
import useDeleteDocument from "../hooks/useDeleteDocument";

function Delete(props) {    
    const id = props.data?.id;
    const { handleSave, loading} = useDeleteDocument(id);
    // TO DO - Да се преизползва за триене и на сесия, няма смисъл от нов компонент

    const onFormSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('id', props.data?.id);
        formData.append('type', props.type);
    
        handleSave(formData, props.tab, props.onHide, props.updateFilesCallback);
    };
    
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Изтриване
                </Modal.Title>
                <CloseButton onClick={props.onHide} variant='white'/>
            </Modal.Header>
            <Modal.Body>
                <Form id="delete-commission" onSubmit={onFormSubmit}>
                    <Form.Control
                        name='id'
                        type="text"
                        hidden
                        value={props.data.id}
                    />
                    <h4>Сигурни ли сте ?</h4>
                    <p>
                        Предприетите от Вас действия може да са необратими.
                    </p>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" form='delete-commission' type="submit" disabled={loading}>
                    Изтриване
                </Button>
                <Button  variant="secondary" onClick={props.onHide}>Затвори</Button>
            </Modal.Footer>
        </Modal>
    )
}
export default Delete